<template>
  <form class="form-cms mt-1">
    <div class="form-control">
      <label for="supermarket">Supermercado:</label>
      <select name="supermarket">
        <option value="plazavea">Plaza Vea</option>
      </select>
    </div>
    <div class="buttons mt-1">
      <button class="btn btn-primary btn-medium">
        <i class="fas fa-bolt"></i> Asociar Productos
      </button>
    </div>
  </form>
</template>
